import React, {useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import {Animated} from "react-animated-css";

export default function Splash(props:any){
    return (
        //@ts-ignore
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={props.loadingAnimation}>
            <div className='relative w-screen h-screen overflow-hidden flex flex-col justify-center items-center space-y-4'>
                <ClipLoader
                    loading={true}
                    size={100}
                    className='border-inactive border-[100px] '
                />
                <span className='font-bold text-black text-lg'>{props.message}</span>
                <span className='absolute bottom-10 right-10 font-bold text-black text-lg'>Checkt.</span>
            </div>

        </Animated>
    )
}
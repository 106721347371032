//@ts-nocheck
import React, { useState, useEffect, useRef} from 'react';
import { CheckCircleIcon, ChevronUpIcon, ChevronDownIcon, SquaresPlusIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { classNames } from '../utils/helpers';
import { FormButton } from '../components/FormButton';
import { ButtonLink } from '../components/ButtonLink';
import { OverlayContext } from '../state/store';
import { useParams, useNavigate, redirect, Outlet, useLocation } from 'react-router-dom';
import { getChecklist } from '../services/queries';
import Splash from '../views/Splash';


/*export default function CreatePage(props:any){
    return (
        <div className='w-full flex flex-col'>
            <CreateListManager/>
        </div>
    )
}*/

function ItemContainer(props){
    return (
        <div className='w-full rounded-primary bg-white h-[80px] flex flex-row justify-between p-1 text-inactive'>
            <div className='w-full flex flex-col items-start'>
                <div className='text-active w-full h-full flex items-center'>
                    {props.data.seq}. {props.data.item_main_content}
                </div>
            </div>
{/*            <div className='border-l-1 h-full flex flex-col justify-center px-4'>
                <ChevronDownIcon className={classNames(
                    
                    props.checked && 'text-green-600',
                    'w-10 h-10 hover:text-active')
                } />
                <span className='text-xs w-fit'>Up</span>
            </div>
            <div className='border-l-1 h-full flex flex-col justify-center px-4'>
                <ChevronUpIcon className={classNames(
                    props.checked && 'text-green-600',
                    'w-10 h-10 hover:text-active')
                } />
                <span className='text-xs w-fit'>Down</span>
            </div>*/}
            
        </div>
    )
}

const testlist = [
    {
        id: 1,
        item_main_content: 'Remove items from car',
        item_seq: 1
    },
    {
        id: 2,
        item_main_content: 'Make sure tank is on half',
        item_seq: 1
    },
    {
        id: 3,
        item_main_content: 'Set appointment with mechanic',
        item_seq: 1
    },
    {
        id: 4,
        item_main_content: 'Take car to mechanic shop',
        item_seq: 1
    },
    {
        id: 5,
        item_main_content: 'Tell mechanic about previous car history',
        item_seq: 1
    },
    {
        id: 6,
        item_main_content: 'Get quoute from mechanic',
        item_seq: 1
    },
    {
        id: 7,
        item_main_content: 'notify office of total costs',
        item_seq: 1
    },
    {
        id: 8,
        item_main_content: 'mention tom for discount',
        item_seq: 1
    },
    {
        id: 9,
        item_main_content: 'give keys to mechanic',
        item_seq: 1
    },
]

const testlist2 = [];

const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result);

    result = result.map((item: any, idx:any) => {return {...item, seq:idx + 1}});
    console.log(result);
    return result;
  };

export const CreatePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [listName, setListName] = useState('');
    const [items, setItems] = useState([]);
    const [originalList, setOriginallist] = useState({});
    const [addItem, setAddItem] = useState('');
    const [addManager, setAddManager] = useState(false);
    const overlayController = React.useContext(OverlayContext);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    }

    const LoadChecklist = async () => {
        const ans = await getChecklist(id);
        
        if(ans === '/'){
            //setLoading(false);
            setTimeout(() => navigate("/404"), 1000);
        } else {
            setListName(ans[0].checklist_name);
            setItems(ans[0].items_content);
            setOriginallist(ans[0]);
            setLoading(false);
        }
    }
    useEffect(() => {
        if(addManager){
            scrollToBottom()
        }
    }, [items]);

    useEffect(() => {
        if(id){
            setLoading(true);
            LoadChecklist();
        }
    }, [id]);

    if(loading){
        return <div className='fixed top-0 left-0 w-screen h-screen border-2'><Splash message={'Retriving List . . .'} loadingAnimation={true}/> </div>;
    }
    return (
        <div className='w-full flex flex-col text-active space-y-2'>
            <div className='z1 w-full flex flex-col justify-start items-start space-y-4 xs:text-xs'>
                <span className='text-lg font-bold'>Checklist Name:</span>
                <div className='w-full flex flex-row justify-between space-x-2'>
                <input
                    onChange={(e) => setListName(e.target.value)}
                    value={listName}
                    type="text"
                    name="listname"
                    id="listname"
                    className="pl-2 block w-[75%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="What shall we call your new checklist?"
                    disabled={false}
                />
                    <FormButton onClick={() => {
                        setAddManager(true);
                        setTimeout(() => scrollToBottom(), 250);
                    }}>
                        <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                            <SquaresPlusIcon className='w-5 h-5' />
                            <label className='text-xs'>Add</label>
                        </div>
                    </FormButton>
                    <ButtonLink to='publish' onClick={() => overlayController.setModal((old: any) => {return {...old, publishData: {items, listName, id: old.publishData.id || id, create: (id ? false : (old.publishData.id ? false : true))}}})}>
                        <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                            <PaperAirplaneIcon className='w-5 h-5' />
                            <label className='text-xs'>{!id && 'Publish'} {id && 'Update'}</label>
                        </div>
                    </ButtonLink>
                </div>
            </div>
            <div className={classNames(addManager && 'pb-[200px]')}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames(
                            snapshot.isDraggingOver && 'bg-gray-100 rounded-primary',
                            'space-y-3'
                        )}
                    >
                    {
                        items.map((item, index) => 
                            <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={classNames(
                                            snapshot.isDragging && 'shadow-2xl border-active',
                                            !snapshot.isDragging && 'border-inactive',
                                            'border-2 rounded-primary shadow-md'
                                        )}
                                    >
                                        <ItemContainer data={item} />
                                    </div>
                                )}
                            </Draggable>
                        )
                    }
                        <div ref={messagesEndRef} />
                        {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            </div>
            {addManager && <div className='z-0 overflow-scroll fixed top-0 left-0 w-screen h-screen space-y-2  sm:px-[30%] bg-gray-600/20'>
                <div className='z-1 absolute bottom-0 left-0 h-[200px] w-full sm:px-[25%] px-[5%]'>
                    <div className='h-full bg-white shadow-2xl border-2 border-inactive rounded-t-primary p-5 flex flex-col space-y-2'>
                        <div className='border-2 h-full w-full flex flex-col justify-start items-start space-y-1 xs:text-xs shadow-sm rounded-primary bg-inactive/20'>
                            <input
                                onChange={(e) => setAddItem(e.target.value)}
                                value={addItem}
                                type="text"
                                name="listname"
                                id="listname"
                                className="pl-2 h-full block w-full rounded-t-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="What shall we call your new checklist?"
                                disabled={false}
                            />
                            <div className='flex flex-row space-x-2 w-full pb-1 px-2'>
                                <button disabled={true} className='p-2 bg-gray-300/90 shadow-sm border-2 font-bold text-active w-[70px]'>Position</button>
                                <button disabled={true} className='p-2 bg-gray-300/90  shadow-sm border-2 font-bold text-active w-[70px]'>Assign</button>
                                <button disabled={true}  className='p-2 bg-gray-300/90  shadow-sm border-2 font-bold text-active w-[70px]'>Due</button>
                            </div>
                        </div>
                        
                        <div className='w-full flex flex-row justify-end items-end space-x-2'>
                            <div className='w-full'></div>
                            <FormButton onClick={() => {
                                setAddManager(false);
                                setAddItem('');
                            }}>Close</FormButton>
                            <FormButton disabled={addItem.length === 0} onClick={() => {
                                setItems((old: any) => [...old, {id: old.length + 1, item_main_content: addItem, seq: old.length + 1}]);
                                setAddItem('');
                            }}>
                                <div className='flex flex-row space-x-2'>
                                    <SquaresPlusIcon className='w-5 h-5' />
                                    <label>Add</label>
                                </div>
                            </FormButton>
                        </div>

                    </div>
                </div>
            </div>}
            <Outlet />
        </div>
    )
}
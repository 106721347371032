import { ButtonLink } from '../components/ButtonLink';
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export default function RouteDoesNotExist(){
    return (
        <div className='-mt-28 w-screen h-screen -mb-12 flex justify-center items-center'>
        <div className='flex flex-col space-y-8 items-center border-2 p-10 rounded-[12px] shadow-lg'>
            <div className='flex flex-col items-center space-y-2'>
                <ExclamationTriangleIcon className='w-20 h-20 text-red-600'/>
                <div className='text-2xl font-bold'>Error</div>
            </div>
            <div>Ooops. We counldn't find what you were looking for!</div>
            <ButtonLink title='Home' to='/'>
                <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                    <label className='text-xs'>Home</label>
                </div>
            </ButtonLink>
        </div>
    </div>
    )
}
import React, { useEffect } from "react";
import { API_URL } from "../App";
import makeRequest from "../utils/request";
import { ProfileContext, LoadingContext } from "../state/store";
import { useNavigate, redirect } from "react-router-dom";

type Props = {
	redirectTo: string;
}

export default function Loggin(props: Props) {
    const navigate = useNavigate();
    const user = React.useContext(ProfileContext);
    const loading = React.useContext(LoadingContext);

    const connectUser = async (hash:any) => {
        const ans = await makeRequest(`${API_URL}/api/v1/supabase/auth?${hash.substring(1)}`, 'POST');
        console.log(ans);
        if(ans){
            user.setUser(ans);
            user.setLoggedIn(true);
            navigate("/");
        };
        loading.removeSplashPage();
    }
	useEffect(() => {
        loading.setSplashLoading((old:any) => { return {loading: true, animate: true, message: 'Loggin in . . .'}})
		const hash = new URL(globalThis.location.href).hash;
		const hashParams = new URLSearchParams("?" + hash.substring(1));

		const access_token = hashParams.get("access_token");
		const refresh_token = hashParams.get("refresh_token");
		const expires_in = hashParams.get("expires_in");


		if (access_token && refresh_token && expires_in) {
            connectUser(hash);
		} else {
			globalThis.location.replace('/');
		}
	});	
	
	return <p className='w-screen h-screen flex justify-center items-center'>Logging in...</p>;
}
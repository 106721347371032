import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/Modal";
import { OverlayContext } from "../state/store";
import React from "react";
import { FormButton } from "../components/FormButton";
//@ts-ignore
import { Switch, Listbox } from '@headlessui/react';
import { SparklesIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import { VIEW } from "../components/Modal";
import { publishChecklist, getChecklistinfo, getQRCode } from "../services/queries";


const ListStyle = [
    {
        id: 1,
        name: "Item"
    },
    {
        id: 2,
        name: "Decision Tree"
    }
]

export default function PublishModal(props:any){
    const navigate = useNavigate();
    const [modalViewStatus, setModalViewStatus] = useState(VIEW.LOADING);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [reviewed, setReviewed] = useState(false);
    const [savedData, setSavedData] = useState<any>({});

    const [listSettings, setListSettings] = useState({
        public: true
    })
    const overlayController = React.useContext(OverlayContext);

    const saveChecklist = async () => {
        //setReviewed(true);
        setModalViewStatus(VIEW.SAVING);
        //console.log([listSettings, overlayController.modal.publishData]);
        //console.log(overlayController.modal.publishData);
        const ans = await publishChecklist([listSettings, overlayController.modal.publishData.items, overlayController.modal.publishData.listName, overlayController.modal.publishData.id]);
        console.log(ans);
        if(overlayController.modal.publishData.create){
            console.log('creating qr code . . . .');
            const qrData = await getQRCode('public', ans.checklist_id);
            setSavedData(qrData);
        } else {
            console.log('getting qr code . . . .');
            const checklistInfo = await getChecklistinfo(ans.checklist_id);
            setSavedData(checklistInfo);
        }
        if(ans){
            success(ans.checklist_id);
            setTimeout(() => setReviewed(true), 1000);
        } else {
            error();
        }
        //setModalViewStatus(VIEW.MAIN_CONTENT);
        //setTimeout(() => success(), 3000);
        //setTimeout(() => setReviewed(true), 3000);
        
    }

    const success = (checklist_id?: string) => {
        overlayController.setModal((old: any) => {
            return {...old, publishData: {...old.publishData, id: checklist_id, create: false} }
        });
        setModalViewStatus(VIEW.SUCCESS);
        setTimeout(() => setModalViewStatus(VIEW.MAIN_CONTENT), 1500);
    }

    const error = () => {
        setModalViewStatus(VIEW.ERROR);
        setTimeout(() => setModalViewStatus(VIEW.MAIN_CONTENT), 1500);
    }

    /*useEffect(() => {
        if(overlayController.modal.publishModal){
            setModalViewStatus(VIEW.LOADING);
            setTimeout(() => setModalViewStatus(VIEW.MAIN_CONTENT), 1500);
        }
    }, [overlayController.modal.publishModal]);*/

    useEffect(() => {
        setTimeout(() => setModalViewStatus(VIEW.MAIN_CONTENT), 1500);
    }, []);

    return(
        <Modal 
            view={modalViewStatus}
            size='h-[300px]'
            openModal={() => console.log('open publish modal')}
            closeModal={() => {
                
                //overlayController.setModal((old: any) => {return {...old, publishModal: false}});
                navigate(-1);
                setReviewed(false);
            }}
            isOpen={true}
            title={
            <div className="w-full flex flex-row justify-between items-center">
                <h1>Publish</h1>
                <div className="hidden xs:flex flex-row space-x-2 items-cente">
                    <SparklesIcon className="w-3 h-3 text-yellow-500" />
                    <label className="text-gray-400 font-bold text-xs">Available to Pro Customers</label>
                </div>
            </div>
            }
            children={<div className='w-full flex flex-col justify-center items-center h-[300px]'>
                {reviewed ? (
                <div className="w-full h-full flex flex-col justify-around space-y-2">
                    <h1 className='font-medium'>Shareables</h1>
                    <div className='w-full p-[1%] flex flex-col items-center justify-center space-y-4'>
                        <img 
                            className='h-[150px] w-[150px] shadow-lg border-2 rounded-primary' 
                            src={savedData.checklist_qr || null} />
                    </div>
                    <div 
                        onClick={() => {navigator.clipboard.writeText(savedData.checklist_link || 'N/A')}}
                        className='flex flex-row space-x-2 items-center text-sm text-active hover:text-active border-b-2 hover:cursor-pointer'>
                            {/*<CopyToClipboard className='hover:cursor-pointer' text={``} onCopy={() => console.log(`copied: ${true}`)}>
                                <DocumentDuplicateIcon className='w-4 h-4 hover:cursor-pointer' />
                                        </CopyToClipboard>*/}
                        <label className='hover:cursor-pointer'>{savedData.checklist_link || 'N/A'}</label>
                        <DocumentDuplicateIcon className='w-4 h-4 hover:cursor-pointer' /> Press to Copy           
                    </div>
                </div>): 
                <div className="w-full h-full flex flex-col space-between">
                    <div className="border-t-2 border-active h-full flex flex-col space-y-4">
                        <div className="w-full font-bold py-2">
                            {`Your checklist contains ${overlayController.modal.publishData?.items?.length} items`}
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <div className="flex flex-row space-x-2 items-center w-[150px]">
                                <SparklesIcon className="w-3 h-3 text-yellow-500" />
                                <label className="text-gray-500 font-bold">Public</label>
                            </div>
                            <Switch
                            disabled={true}
                            checked={listSettings.public}
                            onChange={() => setListSettings((old: any) => {return {...old, public: !listSettings.public}})}
                            className={`${
                                listSettings.public ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span
                                className={`${
                                    listSettings.public ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <div className="flex flex-row space-x-2 items-center w-[150px]">
                                <SparklesIcon className="w-3 h-3 text-yellow-500" />
                                <label className="text-gray-500 font-bold">Collaborative</label>
                            </div>
                            <Switch
                            disabled={true}
                            checked={false}
                            onChange={() => setListSettings((old: any) => {return {...old, public: !listSettings.public}})}
                            className={`${
                                !listSettings.public ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span
                                className={`${
                                    listSettings.public ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <div className="flex flex-row space-x-2 items-center w-[150px]">
                                <SparklesIcon className="w-3 h-3 text-yellow-500" />
                                <label className="text-gray-500 font-bold">Track Usage</label>
                            </div>
                            <Switch
                                disabled={true}
                                checked={false}
                                onChange={() => setListSettings((old: any) => {return {...old, public: !listSettings.public}})}
                                className={`${
                                    !listSettings.public ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span
                                className={`${
                                    listSettings.public ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <div className="flex flex-row space-x-2 items-center w-[150px]">
                                <SparklesIcon className="w-3 h-3 text-yellow-500" />
                                <label className="text-gray-500 font-bold">Notifications</label>
                            </div>
                            <Switch
                                disabled={true}
                                checked={false}
                                onChange={() => setListSettings((old: any) => {return {...old, public: !listSettings.public}})}
                                className={`${
                                    !listSettings.public ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span
                                className={`${
                                    listSettings.public ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                            </Switch>
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-end items-end'>
                        <FormButton onClick={() => {
                            saveChecklist();
                        }}>All Good!</FormButton>
                    </div>
                </div>
                }
            </div>}
        />
    )
}


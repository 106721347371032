import React, {useEffect, useState} from 'react';
import { Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";
import { ProfileContext, LoadingContext, OverlayContext } from './state/store';
import { Layout } from './components/Layout';
import Loggin from './views/Loggin';
import Splash from './views/Splash';
import MainPage from './pages/Main';
import {CreatePage} from './pages/Create';
import RouteDoesNotExist from './views/404';
import { checkCredentials } from './services/queries';
import PublishModal from './views/PublishModal';
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import { useAnalytics } from "./utils/useAnalytics";

export const API_URL : string = process.env.REACT_APP_API ?? '';
export const HOME_URL : string = process.env.REACT_APP_HOME ?? '';

function App() {
  const { init } = useAnalytics();

  const [splashLoading, setSplashLoading] = useState({
    loading: true,
    animation: true,
    message: "Loading . . ."
  });

  const [modal, setModal] = useState({
    publishModal: false,
    publishData: []
  })

  const location = useLocation();
  const [user, setUser] = useState({});
  const [loggedin, setLoggedIn] = useState(false);

  const background = location.state && location.state.background;
  
  useEffect(() => {
    try {
        (async () => {
            const ans = await checkCredentials();
            if(ans){
              setUser(ans.user);
              setLoggedIn(ans.loggedin);
              removeSplashPage();
            }
        })();
    } catch (e) {
      removeSplashPage();
    }
    
  }, []);

  useEffect(() => {
    //console.log('route changed');
    //console.log(location);
    //console.log(location.pathname.split('/')[1]);
    if(location.pathname === '/'){
      setModal({
        publishModal: false,
        publishData: []
      });
    }
  }, [location]);

  const removeSplashPage = () => {
    const timeout = 1500;
    setTimeout(() => setSplashLoading((old: any) => { return {...old, loading: false}}), timeout);
    setTimeout(() => setSplashLoading((old: any) => { return {...old, animation: false}}), timeout - 500);
  }

  if(splashLoading.loading){
    return <Splash message={splashLoading.message} loadingAnimation={splashLoading.animation}/>;
  }
  return (
    <ProfileContext.Provider value={{ user, setUser, setLoggedIn }}> 
      <LoadingContext.Provider value={{ splashLoading, setSplashLoading, removeSplashPage}}>
        <OverlayContext.Provider value={{ modal, setModal }}>
        <AnalyticsWrapper init={init}>
          <Layout user={user} origin={window.location.origin}>
            <Routes location={background || location}>
              <Route path="/" element={
                  <React.Fragment>
                    <MainPage />
                  </React.Fragment>
              } />
              <Route path="/create" element={
                  <React.Fragment>
                    <CreatePage />
                  </React.Fragment>
              }>
                <Route path="publish" element={<PublishModal />} />
              </Route>
              <Route path="/list/:id" element={
                  <React.Fragment>
                    <CreatePage />
                  </React.Fragment>
              }>
                <Route path="publish" element={<PublishModal />} />
              </Route>
              <Route path="/logging" element={
                  <React.Fragment>
                    <Loggin redirectTo='/'/>
                  </React.Fragment>
              } />
              <Route path='*' element={<RouteDoesNotExist />}/>
            </Routes>
            {background && (
              <Routes>
                <Route path="publish" element={<PublishModal />} />
              </Routes>
            )}
          </Layout>
          </AnalyticsWrapper>
        </OverlayContext.Provider>
      </LoadingContext.Provider>
    </ProfileContext.Provider>
  );
}

export default App;

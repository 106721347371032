import { ButtonLink } from "./ButtonLink"
import { FormButton } from "./FormButton"
import { Link } from "./Link"

export const GoogleSignin = (props: {origin: any}) => {
    return <Link
      href={`https://prbssiyiiidtevwmdwsv.supabase.co/auth/v1/authorize?provider=google&redirect_to=${props.origin}/logging`}
  >
    <FormButton>
      <div className='flex flex-row space-x-2 rounded-primary'>
        <div className="left">
            <img width="20px" className="margin-top:0px; margin-right:8px" alt="Google sign-in" 
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
        </div>
        <span>Login with Google</span>
      </div>
      </FormButton>
  </Link>
}
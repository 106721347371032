import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/helpers";

export default function DisclosureElement(props: any) {
    return (
          <Disclosure as="div" className="rounded-primary shadow-md hover:shadow-xl">
            {({ open }) => (
              <>
                <Disclosure.Button className={classNames(
                    !open && 'rounded-primary border-2 border-gray-200 hover:text-active',
                    open && 'rounded-t-primary border-t-2 border-r-2 border-l-2 border-b-2',
                    "w-full h-full flex flex-row justify-between px-2 items-center xs:w-full")
                }>
                  {props.button}
                  <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-active`}
                />
                </Disclosure.Button>
                <Disclosure.Panel className={classNames(
                    !open && 'rounded-primary',
                    open && 'rounded-b-primary border-b-2 border-r-2 border-l-2',
                    "w-full h-full flex flex-row justify-between px-4 items-center xs:w-full py-2 ")}>
                    {props.content}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
    )
  }
import { FormButton } from "./FormButton";
import { Link } from "react-router-dom";

export function ButtonLink(props: any) {
  return (
    <Link
      {...props}
      disabled={props.disabled}
    >
      <FormButton {...props} />
    </Link>
  );
}
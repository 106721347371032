import * as React from 'react';
import ReactGA from "react-ga4";

export function useAnalytics(){
    const [init, setInit] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize('G-CNTWEMWPWF');
        setInit(true);
    }, []);
    return {
        init
    }
}
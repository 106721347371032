//@ts-nocheck
import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ClipLoader from "react-spinners/ClipLoader";
import { classNames } from '../utils/helpers';
import { CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import {Animated} from "react-animated-css";

export enum VIEW {
    MAIN_CONTENT = "MAIN_CONTENT",
    SUCCESS_CONTENT = "SUCCESS_CONTENT",
    LOADING = "LOADING",
    SAVING = "SAVING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export function Modal(props:{
    openModal: () => void;
    closeModal: () => void;
    isOpen: boolean;
    children: any;
    title: any;
    view: View;
    size?: string;
}) {
    const viewToDisplay = () => {
        switch(props.view){
            case VIEW.LOADING:
                return (
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={props.view === VIEW.LOADING}>
                    <div className={classNames(props.size, 'flex flex-col justify-center items-center space-y-4')}>
                        <ClipLoader
                            loading={true}
                            size={100}
                            className='border-inactive border-[100px] '
                        />  
                        <label className='font-bold text-sm'>Loading . . .</label>
                    </div>
                </Animated>);
            case VIEW.SAVING:
                return( 
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={props.view === VIEW.SAVING}>
                    <div className={classNames(props.size, 'flex flex-col justify-center items-center space-y-4')}>
                        <ClipLoader
                            loading={true}
                            size={100}
                            className='border-inactive border-[100px] '
                        />  
                        <label className='font-bold text-sm'>Saving . . .</label>
                    </div>
                </Animated>);
            case VIEW.SUCCESS:
                return(
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={props.view === VIEW.SUCCESS}>
                    <div className={classNames(props.size, 'flex flex-col justify-center items-center space-y-4')}>
                        <CheckBadgeIcon
                            className='w-20 h-20 text-green-600'
                        />  
                        <label className='font-bold text-sm'>SUCCESS!</label>
                    </div>
                </Animated>);
            case VIEW.ERROR:
                return( 
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={props.view === VIEW.ERROR}>
                    <div className={classNames(props.size, 'flex flex-col justify-center items-center space-y-4')}>
                        <ExclamationTriangleIcon
                            className='w-20 h-20 text-red-600'
                        />  
                        <label className='font-bold text-sm'>There was an error.</label>
                    </div>
                </Animated>);
            case VIEW.MAIN_CONTENT:
                return( 
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                    <React.Fragment>
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-bold leading-6 text-gray-900"
                        >
                            {props.title}
                        </Dialog.Title>
                        {props.children}
                    </React.Fragment>
                </Animated>);
            default:
                return( 
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                    <React.Fragment>
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-bold leading-6 text-gray-900"
                        >
                            {props.title}
                        </Dialog.Title>
                        {props.children}
                    </React.Fragment>
                </Animated>);
        }
    }

  return (
    <>
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            
                            <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            {
                                viewToDisplay()
                            }
                            </Dialog.Panel> 
                            
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
  )
}
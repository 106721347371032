import React from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

interface WrapperProps {
    init: boolean;
    children: React.PropsWithChildren<any>;
}

export default function AnalyticsWrapper(props: WrapperProps){
    
    const location = useLocation();
    console.log(location.pathname+location.search);
    React.useEffect(() => {
        if(props.init){
            ReactGA.send(
                { hitType: "pageview", page: location.pathname+location.search, title: `app${location.pathname+location.search}`}
            );
        }
    }, [props.init, location]);

    return props.children;
}
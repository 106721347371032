import React from 'react';
import Header from './Header';
import { useLocation, Outlet } from 'react-router-dom';
import { GoogleSignin } from './GoogleSignin';
import PublishModal from '../views/PublishModal';
import { OverlayContext } from '../state/store';

export function Layout(props:any){
    const overlay = React.useContext(OverlayContext);
    const location = useLocation();

    if((location.pathname !== '/logging') && Object.keys(props.user).length === 0){
        return(
            <div className='w-screee h-screen flex flex-col justify-center items-center'>
                <GoogleSignin origin={props.origin}/>
            </div>
        )
    }

    return (
        <>
            <div className='h-screen flex flex-col overflow-hidden border-2'>
                <Header />
                
                <div className='relative h-screen mt-20 overflow-scroll w-screen px-1 sm:px-[25%] flex justify-start items-start border-2'>
                    {props.children}
                </div>
            </div>
        </>
    )
}
//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { classNames } from "../utils/helpers";
import { ButtonLink } from "../components/ButtonLink";
import { getUserCollections } from '../services/queries';
import { LoadingContext } from '../state/store';
import { ChevronRightIcon, QrCodeIcon, DocumentDuplicateIcon, Square3Stack3DIcon, EyeIcon, UserIcon } from "@heroicons/react/24/solid";
import Splash from '../views/Splash';
import { Link } from 'react-router-dom';
import DisclosureElement from '../components/Disclosure';
import { Switch } from '@headlessui/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const items = [];


export function ChecklistItem(props:any) {
    return (
        <DisclosureElement
        button={
            <div className='w-full px-2'>
                <div className='flex flex-row w-full items-center justify-between'>
                    <div className="px-4 py-4 sm:px-6 flex flex-col justify-start items-start space-y-2 w-full">
                        <div className="flex flex-row justify-between">
                            <p className="text-md font-bold text-active">{props.checklist_name}</p>
                        </div>
                        <div className="mt-2 flex justify-between text-xs font-medium text-inactive space-x-2">
                            <div className='flex flex-col xs:flex-row space-x-1 xs:w-[90px] '>
                                <Square3Stack3DIcon className='w-4 h-4 hidden xs:inline-block' />
                                <span>Items: {props.item_count || 0}</span>
                            </div>
                            <div className='flex flex-col xs:flex-row space-x-1 xs:w-[90px] '>
                                <EyeIcon className='w-4 h-4 hidden xs:inline-block' />
                                <span>Views: {0}</span>
                            </div>
                            <div className='flex flex-col xs:flex-row space-x-1 xs:w-[90px] '>
                                <UserIcon className='w-4 h-4 hidden xs:inline-block' />
                                <span>Checkr's: {0}</span>
                            </div>
 
                        </div>
                    </div>
                    <div className='flex flex-row justify-center items-center space-x-4 h-full'>
                        <div className='h-full hover:text-active'>
                            <QrCodeIcon className='hidden sm:inline-block w-4 h-4 sm:w-6 sm:h-6 text-inactive' />
                        </div>
                    </div>
                </div>
            </div>
        }
        content={
            <div className='w-full flex flex-col items-center'>
                <div className='w-full h-full flex flex-col sm:flex-row'>
                    <div className='h-full w-full flex flex-col space-y-4 my-4 justify-center items-center'>
                        <ul className='h-full m-0 space-y-2 p-2'>
                            <li className='flex flex-row space-x-4 text-sm items-center'>
                                <label className='font-bold w-[100px]'>Public</label>
                                <Switch
                                    disabled={true}
                                    checked={true}
                                    onChange={() => console.log('change')}
                                    className={`${
                                        true ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                    <span
                                        className={`${
                                            true ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </li>
                            <li className='flex flex-row space-x-4 text-sm items-center'>
                                <label className='font-bold w-[100px]'>Collaborative</label>
                                <Switch
                                    disabled={true}
                                    checked={false}
                                    onChange={() => console.log('change')}
                                    className={`${
                                        false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                    <span
                                        className={`${
                                            false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </li>
                            <li className='flex flex-row space-x-4 text-sm items-center'>
                                <label className='font-bold w-[100px]'>Track Usage</label>
                                <Switch
                                    disabled={true}
                                    checked={false}
                                    onChange={() => console.log('change')}
                                    className={`${
                                        false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                    <span
                                        className={`${
                                            false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </li>
                            <li className='flex flex-row space-x-4 text-sm items-center'>
                                <label className='font-bold w-[100px]'>Notifications</label>
                                <Switch
                                    disabled={true}
                                    checked={false}
                                    onChange={() => console.log('change')}
                                    className={`${
                                        false ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span
                                        className={`${
                                            false ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </li>
                        </ul>
                        <div className='flex flex-row h-[50px] items-center justify-center'>
                            <ButtonLink to={`/list/${props.checklist_id}`} title='Update'>
                                <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                                    <label className='text-xs'>Update</label>
                                </div>
                            </ButtonLink> 
                        </div>
                    </div>
                    <div className='w-full p-[1%] flex flex-col items-center justify-center space-y-4'>
                        <img 
                            className='h-[150px] w-[150px] shadow-lg border-2 rounded-primary' 
                            src={props.checklist_qr || null} />
                    </div>
                </div>
                <div 
                    onClick={() => {navigator.clipboard.writeText(props.checklist_link || 'N/A')}}
                    className='flex flex-row space-x-2 items-center text-sm text-inactive hover:text-active border-b-2 hover:cursor-pointer'>
                        {/*<CopyToClipboard className='hover:cursor-pointer' text={``} onCopy={() => console.log(`copied: ${true}`)}>
                            <DocumentDuplicateIcon className='w-4 h-4 hover:cursor-pointer' />
                                    </CopyToClipboard>*/}
                    <label className='hover:cursor-pointer'>{props.checklist_link || 'N/A'}</label>
                    <div className='hidden sm:inline-block text-xs'><DocumentDuplicateIcon className='hidden sm:inline-block w-4 h-4 hover:cursor-pointer' />Copy</div>           
                </div>
            </div>
        } 
        />
    )
}

export function NoItems(){
    return (
        <div className='rounded-primary h-full border-dotted border-2 border-inactive flex flex-col justify-center items-center space-y-4 text-inactive'>
            <span>Nothing to see here. </span>
            <ButtonLink to="/create" >
            <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                <label className='text-xs'>Click to add Checklist</label>
            </div>
            </ButtonLink>
        </div>
    )
}

export default function MainPage(props:any){
    const loading = React.useContext(LoadingContext);
    const [mainLoading, setLoading] = useState(true);
    const [checklists, setChecklists] = useState([]);
    useEffect(() => {
        (async () => {
            const ans = await getUserCollections();
            if(ans){
                setChecklists(ans);
                console.log(ans);
            }
            setTimeout(() => setLoading(false), 500);
        })();
    },[]);
    return(
        <>
        {mainLoading ? <div className='fixed top-0 left-0 w-screen h-screen border-2'><Splash message={'Loading . . .'} loadingAnimation={true}/> </div>:
        <div className={classNames(
            checklists.length === 0 && 'overflow-hidden',
            'w-full h-full')}>
            <div className='h-full w-full'>
                {checklists.length === 0 ? <NoItems /> : 
                <React.Fragment>
                    <div className='w-full flex justify-between items-center py-2 border-b-2'>
                        <h1 className='font-bold text-active text-lg'>Your Checklists</h1>
                        <ButtonLink to="/create" title='New Checklist' className='text-sm'>
                        <div className='flex flex-col space-y-2 hover:cursor-pointer justify-start items-center'>
                            
                            <label className='text-xs'>Add Checklist</label>
                        </div>
                        </ButtonLink>
                    </div>
                    <div className="z20 divide-gray-200 p-4 overflow-y-scroll w-full space-y-4 pb-10">
                        {checklists?.map((item:any) => (
                            <ChecklistItem {...item} />
                        ))}
                    </div>
                </React.Fragment>
                }
            </div>
        </div>}
        </>
    )
}
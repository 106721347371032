export function FormButton(props: any) {
  return (
    <button
      {...props}
      disabled={props.disabled}
      className={`font-bold flex-grow inline-block cursor-pointer px-4 py-2 rounded border(gray-500 2) disabled:(opacity-50 cursor-not-allowed) bg-active border-active text-white hover:bg-black hover:border-black ${
        props.class ?? ""
      }`}
    />
  );
}
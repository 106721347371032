export function Link(props:any) {
    return (
      <a
        {...props}
        disabled={props.disabled}
        className={`text-primary hover:text-primaryStrong ${
          props.class ?? ""
        }`}
      />
    );
  }
import makeRequest from "../utils/request";
import { API_URL } from "../App";
import { useNavigate } from "react-router-dom";

export const checkCredentials = async () => {
    console.log(`${API_URL}/api/v1/checkCredentials`);
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/checkCredentials`, 'GET', true);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}

export const publishChecklist = async (dataToPublish: any) => {
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/publish`, 'POST', true, dataToPublish);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}

export const getUserCollections = async () => {
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/getcollections`, 'GET', true);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}

export const getChecklist = async (checklist_id: string) => {
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/getchecklist/${checklist_id}`, 'GET', true);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}
export const getQRCode = async (type: string, checklist_id: string ) => {
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/getuniquelink/${checklist_id}/${type}`, 'GET', true);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}

export const getChecklistinfo = async (checklist_id: string ) => {
    try {
        const ans = await makeRequest(`${API_URL}/api/v1/getchecklistinfo/${checklist_id}`, 'GET', true);
        if(ans === '/'){
            window.location.href ='/';
        };
        return ans;
    } catch (e){
        console.log(e);
    }
}
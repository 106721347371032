import { request } from "http";

const makeRequest = async (url: string, method: string, cookie?: boolean, body?: any) => {
    const options = {
        method,
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body)
    };
    //@ts-ignore
    return fetch(url, options)
    .then(response => response.json())
    .then(response => {
        if(response.success){
            return response.data;
        } else {
            return response.data;
        }
    })
    .catch(err => console.error(err));
}

export default makeRequest;
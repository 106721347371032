import { Link } from "./Link";

export default function Header(){
    return (
        <div className='z-10 fixed top-0 left-0 h-20 w-screen flex flex-row justify-between items-center bg-white px-5'>
            <Link href="/">
                <span className='font-bold text-black text-lg'>Checkt.</span>
            </Link>
        </div>
    )
}